import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import 'lazysizes'
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
window.moment = require('moment');



Alpine.plugin(focus)
window.Alpine = Alpine



function pengurangan(e) {
  const btn = e.target.parentNode.parentElement.querySelector(
    'button[data-action="pengurangan"]'
  )

  const target = btn.nextElementSibling

  let value = Number(target.value)
  if (value >= 1) {
    value--
    target.value = value

    // target.trigger("change")
  }
}

function penambahan(e) {
  const btn = e.target.parentNode.parentElement.querySelector(
    'button[data-action="pengurangan"]'
  )
  const target = btn.nextElementSibling
  let value = Number(target.value)

  value++
  target.value = value
}

const decrementButtons = document.querySelectorAll(
  'button[data-action="pengurangan"]'
)

const incrementButtons = document.querySelectorAll(
  'button[data-action="penambahan"]'
)

decrementButtons.forEach(btn => {
  btn.addEventListener('click', pengurangan)
})

incrementButtons.forEach(btn => {
  btn.addEventListener('click', penambahan)
})

Alpine.start()



